import { reactive } from "vue";
import {
    arrayUnion,
    collection,
    db,
    doc,
    query,
    where,
    onSnapshot,
    setDoc,
    updateDoc,
    increment,
    getDoc
} from "@/firebase/init";

export const messageStore = reactive({
    chatRooms: [
    ],
    currentMessageList: [
    ],
    lastMessage: [],
    problemSummary: '',
    // problemSummary: 'Struggling with golf performance (specifically in tournaments)- Started 3 years ago, intensified in the last 3 months due to decreased practice after son\'s birth- General time frame: 3 years, more noticeable in the last 3 months- Involvement: User, indirectly affecting wife and son- Occurs mainly during golf tournaments- Recurring problem- Difficulty and impact: 6/10- Original goals: Enjoy playing golf, compete, and perform closer to peak performance- Factors influencing the problem: Lack of practice affecting confidence around the greens- Negative results: Disappointment in tournament performance, feeling down for the remainder of the day',
    async onMessageWasSent (room, message) {
        // called when the user sends a message
        if (this.lastMessage.length > 0) {
            const lastMessage = this.lastMessage.join('')
            var botMessage = {
                'author': 'EMTS',
                'data': {
                    'text': lastMessage
                },
                'type': 'text'
            }
            let newMessages = [botMessage, message]
            room.messageList = [...(Array.isArray(room.messageList) ? room.messageList : []), ...newMessages]
            const messageRef = doc(db, 'chatRooms', room.firebaseId)
            await updateDoc(messageRef, {
                'messageList': arrayUnion(...newMessages)
            })
        } else {
            if (room.messageList.length === 0) {
                room.messageList = [message]
            } else {
                room.messageList = [...room.messageList, message]
            }
            const messageRef = doc(db, 'chatRooms', room.firebaseId)
            await updateDoc(messageRef, {
                'messageList': arrayUnion(message)
            })
        }
    },
    async onMessageWasSentDuringStage (room, message) {
        // called when the user sends a message
        if (this.lastMessage.length > 0) {
            const lastMessage = this.lastMessage.join('')
            var botMessage = {
                'author': 'EMTS',
                'data': {
                    'text': lastMessage
                },
                'type': 'text'
            }
            let newMessages = [botMessage, message]

            const messageRef = doc(db, 'chatRooms', room.firebaseId)
            await updateDoc(messageRef, {
                'messageList': arrayUnion(...newMessages)
            })
        } else {
            const messageRef = doc(db, 'chatRooms', room.firebaseId)
            await updateDoc(messageRef, {
                'messageList': arrayUnion(message)
            })
        }
    },
    async addNewRoom (room) {
        this.chatRooms.push(room)
        console.log('added new room')
        const roomIds = doc(db, 'chatRooms', 'roomIds');
        const docSnap = await getDoc(roomIds);

        if (docSnap.exists()) {
            room.roomId = 'room-' + docSnap.data().currentId.toString()
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
        const newClientChatRef = await doc(collection(db, 'chatRooms'))
        room.firebaseId = newClientChatRef.id
        room.archived = false
        room.newMessagesCount = 1
        room.lastMessage = []
        await setDoc(newClientChatRef, room)
        onSnapshot(doc(db, 'chatRooms', newClientChatRef.id), (doc) => {
            this.lastMessage = doc.data().lastMessage
            this.currentMessageList = doc.data().messageList
        })

        await updateDoc(roomIds, {
            currentId: increment(1)
        });
    },
    async fetchChatRooms() {
        const q = query(collection(db, 'chatRooms'), where('archived', "==", false));
        onSnapshot(q, (querySnapshot) => {
            const chatRooms = [];
            querySnapshot.forEach((doc) => {
                chatRooms.push(doc.data());
            });
            this.chatRooms = chatRooms.sort((a, b) => a.roomId.localeCompare(b.roomId));
        });
    },
    addToLastMessage(text) {
        const endSummaryPattern = /\s*\[(END(_SUMMARY)?)?/;
        const endSummaryFullPattern = /\s*\[END_SUMMARY]/;
        const combinedText = this.lastMessage.join('') + text;

        if (endSummaryPattern.test(combinedText)) {
            console.log('Problem Summary reached (partial)');
            if (endSummaryFullPattern.test(combinedText)) {
                console.log('Problem Summary reached (full)');
                this.problemSummary = combinedText.replace(endSummaryFullPattern, '');
            }
        }
        this.lastMessage.push(text);
    },
    clearMessageList() {
        this.currentMessageList = []
        this.lastMessage = []
    },
    addToProblemSummary(addition, stage){
        switch (stage) {
            case 1: {
                this.problemSummary += ' User experienced the following emotions: '
                break
            }
            case 2: {
                this.problemSummary += ' User experienced the following unfulfilled resources: '
                break
            }
            case 3: {
                this.problemSummary += ' User the following behaviors: '
                break
            }
            case 4: {
                this.problemSummary += ' User shared they had the following motives: '
                break
            }
            case 5: {
                this.problemSummary += ' User shared they hold the following inaccurate beliefs: '

                break
            }
            default:
                break
        }
        addition.forEach(item => {
            if (item.selected) {
                this.problemSummary += item.title + ' at intensity ' + item.intensity + ' '
            }
        })
        console.log(this.problemSummary)
    }
})