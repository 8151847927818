import { createRouter, createWebHistory } from 'vue-router'
import Index from "@/pages/Index.vue";
import SelectionTable from "@/components/SelectionTable.vue";

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/scale',
    name: 'scale',
    component: SelectionTable
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
