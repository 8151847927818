import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import EmtsPlugins from './plugins/emts-plugins'
import Chat from 'vue3-beautiful-chat'

const app = createApp(App)

app.use(Chat)
app.use(EmtsPlugins)
app.use(router)

app.mount('#app')
