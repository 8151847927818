<template>
  <router-view name="nav"/>
  <router-view class="main-container" />
  <router-view name="footer" class="main-footer"/>
</template>

<style lang="scss">
@import '~@/../mdb/scss/index.pro.scss';

#app {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #5e17eb;
  min-height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
