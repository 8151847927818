<template>
  <div class="index">
    <div class="header py-5">
      <MDBContainer class="pt-0 main-container" ref="chatContainer">
        <MDBRow class="py-md-3" center>
          <MDBCol col="6" md="4">
            <img src="@/assets/images/probsolv.png" class="probsolv-logo img-fluid" alt="ProbSolv" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <h1 class="display-4 main-headline py-3">An EMTS Product</h1>
            <MDBSwitch
                label="EMTS Mode"
                wrapperClass="text-start"
                labelClass="text-white"
                v-model="emts"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow class="py-4" center>
          <MDBCol lg="10" xl="8">
            <p class="text-white">ChatEMTS is powered by AI, so if at any time surprises or mistakes occur,
              <a href="mailto:info@probsolv.app" class="text-info">send us and email</a>  and we'll be
              sure to provide the resources you're looking for.</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
            <img :src="imageUrl" v-if="imageUrl" class="img-fluid pb-5" alt="generated image" />
            <font-awesome-icon v-if="!chatFocused" :icon="['fas', 'comment-dots']" class="message-icon" size="2xl"></font-awesome-icon>
<!--            <font-awesome-icon :icon="['fass', 'paper-plane']" class="send-icon" size="lg" />-->
            <beautiful-chat
                :participants="participants"
                :titleImageUrl="titleImageUrl"
                :onMessageWasSent="onMessageWasSent"
                :messageList="currentMessageList"
                :newMessagesCount="newMessagesCount"
                :isOpen="true"
                :close="closeChat"
                :open="openChat"
                :showEmoji="false"
                :showFile="false"
                :showEdition="true"
                :showDeletion="true"
                :showHeader="false"
                :deletionConfirmation="true"
                :showTypingIndicator="showTypingIndicator"
                :showLauncher="false"
                :showCloseButton="false"
                :colors="colors"
                :alwaysScrollToBottom="false"
                :disableUserListToggle="true"
                :messageStyling="messageStyling"
                @onType="handleOnType"
                @edit="editMessage" />

            <MDBBtn class="mb-5 text-white"
                    outline="white"
                    v-if="(messageStore.problemSummary.length > 0) && (stage === 0)"
                    @click="nextStage">Next</MDBBtn>
<!--            <MDBBtn @click="messageStore.addToLastMessage('[END_SUMMARY]')">End</MDBBtn>-->
            <MDBRow v-show="showIndicator">
              <MDBCol>
                <div class="typing-indicator" :class="{ 'extra-padding': stage === 0 }">
                  <p class="d-inline-block text-white">ChatEMTS is typing</p>
                  <MDBSpinner grow color="danger" size="sm" class="mx-1"  />
                  <MDBSpinner grow color="danger" size="sm" class="me-1"/>
                  <MDBSpinner grow color="danger" size="sm" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow v-if="stage > 0 && stage < 6">
          <MDBCol>
            <selection-table :list="stageArray" />
            <MDBBtn @click="nextStage" class="mb-5 text-white"
                    outline="white">Next</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
<!--      <div class="chat-indicator" @click="chatModal = true">-->
<!--        <img src="@/assets/images/chat.png" class="img-fluid" alt="chat" />-->
<!--      </div>-->
    </div>
    <MDBAlert
        v-model="chatAlert"
        id="alert-primary"
        color="primary"
        position="bottom-right"
        stacking
        width="50%"
        appendToBody
        autohide
        :delay="5000"
    >
      Coming Soon!
    </MDBAlert>
    <MDBModal
        id="serviceModal"
        tabindex="-1"
        labelledby="serviceModalLabel"
        v-model="serviceModal"
    >
      <MDBModalHeader class="border-0">
        <MDBModalTitle id="serviceModalLabel"> Tell Us More </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <div class="form" v-show="!submitted">
          <MDBInput label="Full Name" v-model="fullName" class="my-4" />
          <MDBInput label="Email" v-model="email" class="my-4" required />
          <MDBInput label="Zip" v-model="zip" class="my-4" required />
          <MDBSelect
              v-model:options="serviceInterestOptions"
              v-model:selected="serviceInterestSelected"
              multiple
              require
              label="Service Interest"
              class="my-4"
          />
          <MDBSelect
              v-model:options="insuranceInterestOptions"
              v-model:selected="insuranceInterestSelected"
              require
              :preselect="false"
              label="Insurance Carrier"
              class="my-4"
          />
          <h5 class="text-start">When are you available for services?</h5>
          <MDBRow>
            <MDBCol lg="6" class="text-start">
              <MDBSwitch
                  label="Morning 8am - 12pm"
                  v-model="weekdayEarly"
              />
              <MDBSwitch
                  label="Midday 10am - 2pm"
                  v-model="weekdayMid"
              />
              <MDBSwitch
                  label="Evenings 2pm - 6pm"
                  v-model="weekdayLate"
              />
              <MDBSwitch
                  label="Anytime 8am - 6pm"
                  v-model="allDay"
              />
            </MDBCol>
          </MDBRow>
          <h5 class="text-start mt-3">Anything else we should know?</h5>
          <MDBTextarea
              label="Message"
              id="textArea"
              v-model="notes"
              wrapperClass="mb-4"
          />
        </div>

        <h3 v-if="submitted">Thank you for your message, we will contact you soon!</h3>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn v-show="!submitted" color="primary" @click="submitForm" class="my-4">Submit</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <MDBModal
        side
        position="bottom-right"
        direction="right"
        id="chatModal"
        tabindex="-1"
        labelledby="chatModalLabel"
        v-model="chatModal"
        @show="getChatId"
    >
      <MDBModalHeader class="text-white bg-teal">
        <MDBModalTitle id="exampleSideModalLabel1"> Send us a message </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody class="chat-modal bg-transparent">
        <div class="form" v-show="!submittedChatForm">
          <MDBInput label="Full Name" v-model="fullName" class="my-4" required/>
<!--          <small class="text-start">Enter your phone number to receive the messages there</small>-->
          <MDBInput label="Phone" v-model="phone" class="mb-4" />
          <MDBBtn color="primary" @click="openChat">Start Chat</MDBBtn>
        </div>
      </MDBModalBody>
    </MDBModal>
 </div>
</template>

<script>
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBAlert,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
    MDBTextarea,
    MDBSpinner,
    MDBSwitch
} from 'mdb-vue-ui-kit'
import { ref } from 'vue'
import { addDoc, collection, db, doc, getDoc } from '@/firebase/init'
import { messageStore } from "@/components/messageStore.js";
import SelectionTable from "@/components/SelectionTable.vue";

export default {
  name: "EMTSIndex",
  components: {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBAlert,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBSelect,
    MDBSwitch,
    MDBTextarea,
    MDBSpinner,
    SelectionTable
  },
  setup() {
    const serviceInterestOptions = ref([
      { text: "Applied Behavior Analysis Therapy", value: 0 },
      { text: "Speech Pathologist", value: 1 },
      { text: "Occupational Therapy", value: 2 }
    ]);
    const serviceInterestSelected = ref([]);
    const insuranceInterestOptions = ref([
      { text: "Medical", value: 0 },
      { text: "Anthem", value: 1 },
      { text: "Aetna", value: 2 },
      { text: "Cigna", value: 3 },
      { text: "Community health group", value: 4 },
      { text: "Optum", value: 5 },
      { text: "Kaiser", value: 6 },
      { text: "Magellan", value: 7 },
      { text: "MHN", value: 8 },
      { text: "Molina", value: 9 },
      { text: "Tricare", value: 10 },
      { text: "Other", value: 11 }
    ]);
    let stageArray = ref([]);
    const insuranceInterestSelected = ref([]);
    const weekdayEarly = ref(false)
    const weekdayMid = ref(false)
    const weekdayLate = ref(false)
    const allDay = ref(false)
    const notes = ref('')
    const chatModal = ref(false)
    return {
      serviceInterestOptions,
      serviceInterestSelected,
      insuranceInterestOptions,
      insuranceInterestSelected,
      weekdayEarly,
      weekdayMid,
      weekdayLate,
      allDay,
      notes,
      chatModal,
      stageArray
    };
  },
  async created(){
    if(this.$route.query.sms) {
      this.sentFirstMessage = true
      const roomIds = doc(db, 'chatRooms', this.$route.query.user);
      const docSnap = await getDoc(roomIds);
      await this.messageStore.fetchChatRooms()
      if (docSnap.exists()) {
        this.newRoom = docSnap.data()
        var secondAutoResponse = {
          'msisdn': '18335786996',
          'to': this.newRoom.participants[1],
          'author': 'EMTS',
          'data': {
            'text': 'Great, you will receive all responses via ' + this.newRoom.participants[1].phone +
                '.' + ' You may close this window.'
          },
          'type': 'text'
        }
        await this.messageStore.onMessageWasSent(this.newRoom, secondAutoResponse)
        this.isChatOpen = true
        this.chatModal = true
        this.submittedChatForm = true
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    else {
      await this.openChat()
      await this.sendTypedMessage(`What kind of problem are you experiencing?`)
    }
    document.getElementsByClassName('sc-user-input--text')[0]
        .addEventListener("focus", ()=> {
          this.chatFocused = true
    })
    document.getElementsByClassName('sc-user-input--text')[0]
        .addEventListener("blur", ()=> {
          this.chatFocused = false
        })
  },
  mounted(){
    window.addEventListener('scroll', () => {
      if(!this.autoScrollInProgress) {
        this.userScrolled = true
        this.autoScrolling = false;
        console.log('user scrolled: ' + this.userScrolled)
      }
    });
  },
  unmounted() {
    // document.getElementsByClassName('sc-user-input--text')[0].removeEventListener('focus')
  },
  data(){
    return {
      messageStore,
      lastMessage: [''],
      needs: [],
      behaviors: [],
      motives: [],
      beliefs: [],
      problemSummary: '',
      stage: 0,
      userScrolled: false,
      autoScrolling: true,
      autoScrollInProgress: false,
      emts: false,
      emotionStage: false,
      emotionRatingStage: false,
      needStage: false,
      behaviorStage: false,
      motiveStage: false,
      beliefStage: false,
      chatAlert: false,
      serviceModal: false,
      checkingLine: true,
      lineAvailable: false,
      sentFirstMessage: false,
      chatFocused: false,
      showIndicator: false,
      newRoom: {
        roomId: '',
        participants: [
          {
            id: 'user1',
            name: 'EMTS',
            imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
          },
        ], // the list of the messages to show, can be paginated and adjusted dynamically
        newMessagesCount: 0,
        isChatOpen: false, // to determine whether the chat window should be open or closed
        showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
        alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
        messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
      },
      fullName: '',
      email: '',
      phone: '',
      zip: '',
      clientRef: '',
      chatId: '',
      currentMsisdn: '',
      messageSnap: '',
      availability: [
        this.weekdayEarly,
        this.weekdayMid,
        this.weekdayLate,
        this.allDay
      ],
      submitted: false,
      submittedChatForm: false,
      participants: [
        {
          id: 'user1',
          name: 'EMTS',
          imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
        },
        {
          id: this.fullName,
          name: this.fullName,
          imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
        }
      ], // the list of all the participant of the conversation. `name` is the username, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      newMessagesCount: 0,
      rawEmotionText: [],
      imagePrompt: [],
      imageUrl: '',
      promptList: [
        {
          firstPrompt: `You are a helpful assistant. You are kind and respond with messages of empathy to the user.
          Empathy messages
            are defined as expressing thinking and feeling similar to the emotional state of the user. Some examples are:
            I can imagine that would be difficult; That sucks; That doesn't seem fair.

            Your role is to guide the user through the following template, asking short questions that relate to each
            point, one at a time. If a user has provided the answer already, you can skip the question:

            1. Ask what exactly the problem is. If the answer doesn't include any of the following, Who? What? When?
            Where? Why?, ask a follow up to fill in the gaps.
            Come up with a short title for the problem, as concisely as possible but not missing any important detail, and
            save it for the summary at the end. Pay special importance to who is involved.

            2. Then ask when the problem begin. Try to determine with follow up questions what specific event(s) caused
            this problem to develop? If message-based, it either started with a thought that someone created or it
            stemmed from something someone did, did not do, said or did not say. Save the response and summarize for the
            final response.

            3.Ask questions to determine how long this problem has lasted? Establish a general time frame, from the start
            of the problem to its current status. Has it existed for a day, week, month, year or several years?

            4. Ask questions to determine who or what was involved. A problem may be a solo one or it may involve one
            or more other people. Identify all the people who were involved, contributed or were affected. If the problem
            arose from an event, object or something other than a person, what was it?

            5. Ask questions to determine where this problem tends to occur. It may be a physical location such as
            workplace, school, gym, church, on the road
            or primarily within someone’s personal identity.

            6. Determine if it is a one-time problem or recurring? It may be difficult to identify how often a problem
            occurs because negative ramifications can be ongoing and someone’s level of conscious awareness might vary.

            7. Ask questions to determine how strong the difficulty and impact of this problem has been. Ensure the user
            is aware that it’s crucial to make sure they’re not comparing its intensity with someone else’s.

            8. Ask questions to determine what the original goals that the user were. What were they trying or hoping
            to achieve?

            9. Ask questions to determine what they think influenced this to become a problem. Try to determine what
            they think were the reasons for the problem.

            10. Ask questions to determine what the negative results of this problem were. Determine if the problem is
            likely going to cause several negative results. List them all.

            When every aspect is complete, provide a short summary of each point to the user in the form of a bulleted
            list, including a problem statement as the title.

            Add to the end of the summary response the string "[END_SUMMARY]".` ,
          secondPrompt: '',
          firstUserMessage: ''
        },
        {
          firstPrompt: 'You are a helpful assistant. You know the user is struggling with a problem, summarized as follows: ',
          secondPrompt: `
          You are kind and respond with messages of empathy to the user. Empathy messages
            are defined as expressing thinking and feeling similar to the emotional state of the user. You send messages
            that fulfill the needs of the user, including the needs of understanding and significance.

          Your role is to guide the user through the following problem solving template about emotion.
          The possible emotions the user may be experiences are:
          Accused, Ambivalent, Angered, Anxious, Apathy, Bored, Compulsive, Conflicted, Confused, Contempt, Controlled,
          Criticized, Defeated, Defensive, Devalued, Disappointed, Disliked, Disorganized, Distracted, Doubtful,
          Embarrassed, Fatigued, Fearful, Frustrated, Grieved, Guilty, Helpless, Hopeless, Hurt, Inadequate, Incapable,
          Irritated, Lonely, Loss of Control, Manipulated, Mistreated, Misunderstood, Obligated, Submissive, Pessimistic,
          Provoked, Regretful, Rejected, Resentful, Selfish, Overly Stressed, Suspicious, Threatened, Trapped, Unmotivated,
          Unsatisfied, Vulnerable, Weakened, Withdrawn, Wronged
          `,
          firstUserMessage: `Of the possible emotions, given my problem, please provide a list of the top ten most
          likely emotions I experienced. For each emotion, include a description that relates to my problem.
          Please present your response as a JavaScript array of objects in the format:

          [
            { "title": "", "description": "" },
            { "title": "", "description": "" },
            ...
          ]`
        },
        {
          firstPrompt: 'You are a helpful assistant. You know the user is struggling with a problem, summarized as follows: ',
          secondPrompt: `
          You are kind and respond with empathy to the user. You send messages
            that fulfill the needs of the user, including the needs of understanding and significance.

          Your role is to guide the user through the following problem solving template about resource fulfillment. The
          possible resources that may be unfulfilled for the user are:
          Sense of Power, Contribution, Sense of Control, Enjoyment, Peacefulness, Challenge, Respite, Expression,
          Purpose, Productivity, Health, Self-Acceptance, Freedom, Sense of Importance, Comfort, Understanding,
          Contentment, Significance, Constructive, Objectivity, Skill Training, Boundaries, Opportunity, Safety,
          Sense of Identity
          `,
          firstUserMessage: `Of the possible resources, given my problem, what are the top ten most likely that I
              experienced as unfulfilled during the problem? For each resource, include a description that relates to my problem.

              Please present your response as a JavaScript array of objects in the format:

              [
                { "title": "", "description": "" },
                { "title": "", "description": "" },
                ...
              ]
              `
        },
        {
          firstPrompt: 'You are a helpful assistant. You know the user is struggling with a problem, summarized as follows: ',
          secondPrompt: `
          You send messages
            that fulfill the needs of the user, including the needs of understanding and significance. Each message you send also
            fulfills the need of empathy.
          Your role is to guide the user through the following problem solving template about behavior. The possible
          aggressive behaviors that the user may have done are:
          Avoidance, Low Exertion, Acted Incapable, Acted Afraid, Procrastinated, Indecision, Apathy, Laziness,
          Overly Dependent, Overly Permissive, Excessive Guilt, Excessive Fear, Excessive Stress, Excessive Anger,
          Excessive Grief, Self-Deception, Complaint, Excuses, Blaming, Conflicted, Entitlement, Threatening, Striking,
          Yelling, Badgering, Name Calling, Restricting, Taking, Interrupting, Punishing, Avoidance, Low Exertion,
          Acting Incapable, Acting Afraid, Procrastinating, Indecisiveness, Apathy, Laziness, Overly Dependent, Overly
          Permissive
          `,
          firstUserMessage: `Of the possible aggressive behaviors, given my problem, what are the top ten most likely behaviors
              that I exhibited during the problem?
              Please present your response as a JavaScript array of objects in the format:

              [
                { "title": "", "description": "" },
                { "title": "", "description": "" },
                ...
              ]
              `
        },
        {
          firstPrompt: 'You are a helpful assistant. You know the user is struggling with a problem, summarized as follows: ',
          secondPrompt: `
          You are kind and respond with messages of empathy to the user. Empathy messages
            are defined as expressing thinking and feeling similar to the emotional state of the user.

          Your role is to guide the user through the following problem solving template about motives. The possible
          destructive motives that the user may have experienced are:
          EXCESSIVE PRIDE
          EXCESSIVE DESIRE
          LAZINESS/APATHY
          OVERLY ENTITLED
          DESTRUCTIVE DESIRE
          `,
          firstUserMessage: `Of the possible destructive motives, given my problem, what are the likely reasons that I
              might have had related to the problem?
              Please present your response as a JavaScript array of objects in the format:

              [
                { "title": "", "description": "" },
                { "title": "", "description": "" },
                ...
              ]
              `
        },
        {
          firstPrompt: 'You are a helpful assistant. You know the user is struggling with a problem, summarized as follows: ',
          secondPrompt: 'You are kind and respond with messages of empathy to the user. Empathy messages ' +
              'are defined as expressing thinking and feeling similar to the emotional state of the user. \n' +
              '\n' +
              'Your role is to guide the user through the following problem solving template about beliefs. The possible ' +
              'problematic motives that the user may have experienced are: \n' +
              'I am less/more significant\n' +
              'I am more/less important\n' +
              'I am too weak/too strong\n' +
              'I deserve/don’t deserve\n' +
              'I am less/more lovable \n' +
              'I’m inferior/superior\n',
          firstUserMessage: `Of the possible problematic beliefs, given my problem, what are the likely beliefs that I
            might have had related to the problem?

            Please present your response as a JavaScript array of objects in the format:

              [
                { "title": "", "description": "" },
                { "title": "", "description": "" },
                ...
              ]
              `
        },
        {
          firstPrompt: 'Here is a summary of the user\'s problem: ',
          secondPrompt: `
          You are kind and respond with messages of empathy to the user. Empathy messages
          are defined as expressing thinking and feeling similar to the emotional state of the user.
          Provide a possible plan for the user to solve the problem, using the following solution development stages:

          SOLUTION DEVELOPMENT STAGES:
          7. PERSONAL GOALS  Identify what’s needed to solve the problem.
          8. RELATIONAL GOALS  Identify what’s needed between you and another person to solve the problem.
          9. APPROACH OPTIONS  Cost/benefit analysis of the approaches.
          10. THE RESOURCES  Indicate personal support & structure to solve the problem.
          11. THE BARRIERS  Identify likely obstacles to mission success.
          12. THE MESSAGES  Identify internal & relational messages to solve the problem
          13. ACTION PLAN  Indicate the exact steps for mission success.
          14. IMPLEMENTATION  Get on with it!
          15. EVALUATION  Assess level of progress.

          In order to  accomplish any goal or solve any problem, the user will need to take a number of actions.

          It’s wise for the user to know exactly what to think, say or do in any scenario. For all problems the goal is that
           the user's messaging improves by learning to be more calm-assertive in their approach. The principles
          we’re about to offer should be of tremendous help to you in this training process.

          It's also important for the user to know that calm-assertion is always the ultimate goal for any behavior or
          thought.
          Calm is defined as being undisturbed, peaceful, untroubled,
          still, quiet and in control. Assertion is to declare positively, to be true, to put into action,
          move forward, to state strongly, to claim or to insist.

          Calm Assertion means to be activated within the moment with the effective and beneficial management of thought,
          emotion, desire and behavior, including expressions through verbal and non-verbal communication as well as
          through the reception of incoming messages.

          Think about the times in your life when you’ve experienced the greatest levels of self-control, felt you had
          the most healthy power possible, the strongest trust in your willingness and ability to think, say and do with
          the utmost benevolence (well meaning and kindly) to yourself and toward someone else.

          Think about those particular tasks or activities in which you know you’re confident of being as strong,
          enduring and flexible as you need to be, in order to gracefully navigate through obstacles.

          Think about when you're at your most peaceful and joyful.

          Think about when you feel the most alive, fulfilled and free.

          Think about when you're the most productive and you know you're making a positive contribution to other people.

          These are the times in life when you’re adopting and activating a calm and assertive approach to managing
          yourself. In essence, to be calm and assertive means you’re truly being loving to yourself and to anyone you
          influence. There are levels to this, different shades and nuances, yet the bottom line is simply choosing the
          kindest, most constructive way possible.

          In order to truly operate through calm assertion, you need to be composed, moderated and balanced. Devoid of
          any self or relational aggression, passivity or  passive-aggression.

          You have to be courageous, present in the moment, activated, interested, optimistic and of the faith that this
          is the best way to approach the moment and not be overly concerned about results.

          You think, say and do what’s truly good for yourself and others. Your messaging contains no negativity, abuse
          or neglect. Instead, it aims to fulfill need and be positive, no matter what the circumstances or obstacles.
          Your messages are likable, safe and nutrient-dense.
          `,
          firstUserMessage: 'How should I solve my problem?'
        }
      ],
      isChatOpen: false, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#54b3a3',
          text: '#ffffff'
        },
        launcher: {
          bg: '#54b3a3'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#54b3a3',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  methods: {
    async submitForm () {
      this.submitted = true
      let interest = []
      this.serviceInterestOptions.forEach(service => {
        if(service.selected){
          interest.push(service.text)
        }
      })
      let insurance = ''
      this.insuranceInterestOptions.forEach(carrier => {
        if(carrier.selected) {
          insurance = carrier.text
        }
      })
      let availability = []
      if (this.allDay) {
        availability.push('all day')
      } else {
        if (this.weekdayEarly) {
          availability.push('Weekday Early')
        }
        if (this.weekdayMid) {
          availability.push('Weekday Midday')
        }
        if (this.weekdayLate) {
          availability.push('Weekday Late')
        }
      }

      var mailData = {
        from: "Inquiry Update <admin@behaviorhq.com>",
        to: "jacob@behaviorhc.com",
        bcc: "jthomas@behaviorhq.com",
        replyTo: this.email,
        message: {
          subject: "There is a new inquiry",
          html: this.fullName + ' is interested in ' + interest.join(', ') + '. They are available: ' + availability.join(', ') + ', in zip: '
              + this.zip + ' with: ' + insurance + '<br><br>' + this.notes,
          text: "There is a new message, check firebase"
        }
      }

      await addDoc(collection(db, 'mail'), mailData)
    },
    async getChatId(){
      // const newChatRef = doc(collection(db, 'messages'))
      // this.chatId = newChatRef.id
      // console.log(this.chatId)
    },
    async onMessageWasSent (message) {

      var messageData = {
        'msisdn': '18335786996',
        'to': this.phone,
        'author': 'me',
        'user': this.fullName,
        'data': {
          'text': message.data.text
        },
        'type': 'text'
      }

      await this.messageStore.onMessageWasSent(this.newRoom, messageData)
      console.log('user scrolled set to false')
      this.userScrolled = false
      this.autoScrolling = true
      this.scrollToBottom()
      let prompt
      if (this.stage === 0) {
        prompt = {
          role: 'system',
          content: this.promptList[this.stage].firstPrompt
        }
      } else {
        let content = this.promptList[this.stage].firstPrompt + messageStore.problemSummary + this.promptList[this.stage].secondPrompt
        prompt = {
          role: 'system',
          content: content
        }
      }

      let messageList = [prompt, ...this.apiMessages]

      let eventSource

      if (this.emts) {
        eventSource = new EventSource(
            `https://chat-emts-dsfhsu6hra-uc.a.run.app?question=${encodeURIComponent(JSON.stringify({text: messageData.data.text }))}`
        )
      } else {
        eventSource = new EventSource(
            `https://call-openai-dsfhsu6hra-uc.a.run.app?text=${encodeURIComponent(JSON.stringify(messageList))}`
        )
      }

      eventSource.addEventListener('message', onData);
      eventSource.addEventListener('error', onError)
      eventSource.addEventListener('open', onOpen);

      function onOpen() {
        console.log('Connection to server opened');
      }

      function onData(event) {
        // Handle data received from the stream
        if (event.data == 'Stream complete!') {
          onComplete()
        } else {
          let newData = event.data
          const regex = /\b(?:e\.g\.|i\.e\.)|([.!?:](?!\s))/gm
          const charRegex = /[A-Za-z0-9]/
          console.log(event.data)
          if ((regex.test(messageStore.lastMessage.at(-1)) && !event.data.startsWith(' '))
              || ((/[a-zA-Z]/.test(messageStore.lastMessage.at(-1)) && event.data.match(/^\d/)))
              || ((charRegex.test(messageStore.lastMessage.at(-1)) && event.data.startsWith('- ')))) {
            newData = '\n\n' + newData
          }

          messageStore.addToLastMessage(newData)
        }
      }
      let onComplete = () => {
        // Stream finished, remove event listeners
        console.log('Stream complete')
        eventSource.removeEventListener('message', onData);
        eventSource.removeEventListener('error', onError);
        eventSource.removeEventListener('open', onOpen)
        eventSource.close()
        this.showIndicator = false
        if (!this.userScrolled) {
          this.scrollToBottom()
        }
      }

      function onError(error) {
        console.log('An error occurred while streaming the data from the server: ' + error.valueOf())
      }

      console.log('new message sent: ' + messageData)
      this.showIndicator = true
    },
    addObjectToStageArray(obj){
      this.stageArray.push(obj);
      this.$emit('stageArrayUpdated', this.stageArray);
    },
    async callCreateImage(prompt) {
      const url = 'https://image-generator-dsfhsu6hra-uc.a.run.app';
      const requestBody = {
        prompt,
        n: 1,
        size: '1024x1024',
        response_format: 'url',
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        const data = await response.json();
        console.log('Generated image data:', data);
        if (data) {
          this.imageUrl = data.url
        }
      } catch (error) {
        console.error('Error calling createImage function:', error.message);
      }
    },
    async onMessageWasSentDuringStage (message, image=false) {
      var vm = this
      vm.userScrolled = false
      vm.autoScrolling = true

      return new Promise((resolve, reject) => {
        let prompt
        if (this.stage === 0) {
          prompt = {
            role: 'system',
            content: this.promptList[this.stage].firstPrompt
          }
        } else {
          let content = this.promptList[this.stage].firstPrompt + messageStore.problemSummary + this.promptList[this.stage].secondPrompt
          prompt = {
            role: 'system',
            content: content
          }
        }

        let firstUserMessage = {
          role: 'user',
          content: message.data.text
        }

        let messageList = [prompt, firstUserMessage]

        if (image) {
          messageList = [
            {
              role: 'system',
              content: `You are a helpful assistant. Your role is to generate DALL-E prompts for image generation.
            The prompt should be for a long, wide, full shot. Be creative with lighting, camera settings, and camera angle,
            depending on the user's messages. The prompt should produce a photo-realistic 4k image.
            Keep the prompt under 1000 characters.
            `
            },
            firstUserMessage
          ]
        }

        const eventSource = new EventSource(
            `https://call-openai-dsfhsu6hra-uc.a.run.app?text=${encodeURIComponent(JSON.stringify(messageList))}`
        )

        eventSource.addEventListener('message', onData);
        eventSource.addEventListener('error', onError)
        eventSource.addEventListener('open', onOpen);

        function onOpen() {
          console.log('Connection to server opened');
        }
        let currentObject = {};
        let currentKey = '';
        let state = 'WAIT_FOR_BRACKET_OR_NUMBER';
        let streamComplete = false;

        function onData(event) {
          // Handle data received from the stream
          if (event.data == 'Stream complete!') {
            streamComplete = true;
          } else {
            let newData = event.data
            // const sentenceEndRegex = /[.?!]/g;
            // const message = event.data.split(sentenceEndRegex).join('\n');
            const regex = /\b(?:e\.g\.|i\.e\.)|([.!?:](?!\s))/gm
            if (regex.test(messageStore.lastMessage.at(-1)) && (!event.data.startsWith(' '))) {
              newData = '\n\n' + newData
            }
            if (image) {
              vm.imagePrompt.push(newData)
            } else {
              for (let i = 0; i < newData.length; i++) {
                const char = newData[i];

                switch (state) {
                  case 'WAIT_FOR_BRACKET_OR_NUMBER':
                    if (char === '[') {
                      state = 'WAIT_FOR_OBJECT';
                    } else if (/\d/.test(char)) {
                      state = 'READ_NUMBER';
                    }
                    break;

                  case 'READ_NUMBER':
                    if (char === '.') {
                      state = 'WAIT_FOR_OBJECT';
                    }
                    break;

                  case 'WAIT_FOR_OBJECT':
                    if (char === '{') {
                      state = 'WAIT_FOR_KEY';
                      currentObject = {};
                      currentObject['intensity'] = 5;
                      currentObject['selected'] = false;
                      vm.addObjectToStageArray(currentObject);
                    }
                    break;

                  case 'WAIT_FOR_KEY':
                    if (char === '"') {
                      state = 'READ_KEY';
                    }
                    break;

                  case 'READ_KEY':
                    if (char === '"') {
                      state = 'WAIT_FOR_COLON';
                      currentObject[currentKey] = '';
                    } else {
                      currentKey += char;
                    }
                    break;

                  case 'WAIT_FOR_COLON':
                    if (char === ':') {
                      state = 'WAIT_FOR_VALUE';
                    }
                    break;

                  case 'WAIT_FOR_VALUE':
                    if (char === '"') {
                      state = 'READ_VALUE';
                    }
                    break;

                  case 'READ_VALUE':
                    if (char === '"') {
                      currentKey = '';
                      state = 'WAIT_FOR_COMMA_OR_END';
                    } else {
                      currentObject[currentKey] += char;
                      const index = vm.stageArray.indexOf(currentObject)
                      const deepCopy = JSON.parse(JSON.stringify(currentObject))
                      vm.stageArray.splice(index, 1, deepCopy)
                      if (!vm.userScrolled) {
                        vm.scrollToBottom()
                      }
                    }
                    break;

                  case 'WAIT_FOR_COMMA_OR_END':
                    if (char === ',') {
                      state = 'WAIT_FOR_KEY';
                    } else if (char === '}') {
                      state = 'WAIT_FOR_OBJECT_OR_ARRAY_END';
                    }
                    break;

                  case 'WAIT_FOR_OBJECT_OR_ARRAY_END':
                    if (char === ',') {
                      state = 'WAIT_FOR_OBJECT';
                    } else if (char === ']') {
                      console.log('Array complete');
                    }
                    break;

                  default:
                    console.error('Unknown state:', state);
                }
              }
            }
          }
          // Check if the stream is complete and there's no more data to process
          if (streamComplete) {
            onComplete();
          }
        }

        let onComplete = () => {
          // Stream finished, remove event listeners
          console.log('Stream complete')
          eventSource.removeEventListener('message', onData);
          eventSource.removeEventListener('error', onError);
          eventSource.removeEventListener('open', onOpen)
          eventSource.close()
          vm.showIndicator = false
          if (!vm.userScrolled) {
            vm.scrollToBottom()
          }
          resolve(); // Resolve the Promise when the stream is complete
        }

        function onError(error) {
          console.log('An error occurred while streaming the data from the server: ' + error.valueOf())
          reject(error); // Reject the Promise in case of an error
        }

        console.log('new message sent: ' + message.data.text)
        vm.showIndicator = true
        if (!vm.userScrolled) {
          vm.scrollToBottom()
        }
      })
    },
    async nextStage() {
      messageStore.addToProblemSummary(this.stageArray, (this.stage + 1))
      await messageStore.clearMessageList()
      this.stageArray = []
      this.stage += 1
      const stageMessages = [
        'Given the problem you described, here are 10 possible emotions you experienced. Select the ' +
        'ones that apply, or click more to see others. Click done when you are finished.',
        'Given the emotions you selected, here are 10 possible resources you experienced as unfulfilled. Select the ' +
        'ones that apply and click done when you are finished.',
        'Given the resources you believed to be unfulfilled, here are 10 possible behaviors you exhibited. Select the ' +
        'ones that apply and click done when you are finished.',
        'Given the behaviors you selected, here are some possible motives you experienced. Select the ' +
        'ones that apply and click done when you are finished.',
        'Given the motives you selected, here are 10 possible inaccurate or unproductive beliefs you may hold. Select the ' +
        'ones that apply and click done when you are finished.',
        'Here is a potential game plan for a solution: ',
      ]
      const messageData = {
        'data': {
          'text': this.promptList[this.stage].firstUserMessage
        }
      };
      await this.sendTypedMessage(stageMessages[this.stage - 1])
      // if (this.stage === 6) {
      //   messageStore.clearMessageList()
      // }
      if (this.stage !== 6) {
        await this.onMessageWasSentDuringStage(messageData)
      } else {
        await this.onMessageWasSent(messageData)
      }

      if (this.stage === 1) {
        let firstMessage = {
          data: {
            text: 'Create an image that depicts this summary: ' + messageStore.problemSummary
          }
        }
        await this.onMessageWasSentDuringStage(firstMessage, true)
        console.log(this.imagePrompt.join(''))
        await this.callCreateImage(this.imagePrompt.join(''))
      }
    },
    async sendTypedMessage(message) {
      let messageList = message.split(' ')
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      async function executeSlowly(list) {
        for (const word of list) {
          await delay(50);
          messageStore.addToLastMessage(word + ' ');
        }
      }

      await executeSlowly(messageList);
    },
    scrollToBottom(){
      if (this.$refs.chatContainer !== undefined
          && this.autoScrolling
          && !this.userScrolled
          && !this.autoScrollInProgress) {
        this.autoScrollInProgress = true
        console.log('autoScrolling    InProgress, user scrolled: ' + this.userScrolled)
        var container = this.$refs.chatContainer
        container.$el.scrollTop = container.$el.scrollHeight
        if (window.matchMedia('(min-device-width: 768px)').matches || ((this.stage > 0) && this.stage < 6)) {
          window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        } else {
          var chatMessage = document.querySelectorAll(".sc-message:nth-last-of-type(2)")
          if (chatMessage[0]){
            window.scroll({
              top: chatMessage[0].offsetTop + chatMessage[0].offsetParent.offsetTop,
              left: 0,
              behavior: 'smooth'
            })
          }
        }
        setTimeout(() => {
          console.log('autoScrollingNoLongerInProgress')
          this.autoScrollInProgress = false
        }, 500)
      }
    },
    async openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      // setTimeout(document.getElementsByClassName('sc-user-input--text')[0].focus(), 1.5)

      this.newMessagesCount = 0
      console.log('open chat called')

      let newClientData = {
        id: 'user2',
        name: this.fullName,
        email: '',
        phone: this.phone,
        zip: '',
        clientRef: '',
        imageUrl: require('@/assets/images/user-solid.svg')
      }

      this.newRoom.participants.push(newClientData)
      this.newRoom.msisdn = '12013711010'
      await this.messageStore.addNewRoom(this.newRoom)

      this.submittedChatForm = true
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    }
  },
  computed: {
    emotions() {
      return this.stageArray
    },
    currentMessageList(){
      if (messageStore.lastMessage.length !== 0) {
        const regex = /\s*(\[EN(?:D_SUMMARY)?\])\s*/g;
        const lastMessage = messageStore.lastMessage.join('').replace(regex, '')
        var messageData = {
          'author': 'EMTS',
          'data': {
            'text': lastMessage
          },
          'type': 'text'
        }
        const appendedMessageList = [
          ...(Array.isArray(messageStore.currentMessageList) ? messageStore.currentMessageList : []),
          messageData,
        ]
        return appendedMessageList
      } else {
        return messageStore.currentMessageList
      }
    },
    apiMessages(){
      let apiMessages = []
      if (this.currentMessageList.length > 0) {
        this.currentMessageList.forEach(messageData => {
          let role
          if (messageData.author !== 'EMTS') {
            role = 'user'
          } else {
            role = 'assistant'
          }
          apiMessages.push({
            role: role,
            content: messageData.data.text
          })
        })
      }
      return apiMessages
    }
  },
  watch: {
    'currentMessageList': {
      handler(newValue, ) {
        if (newValue && newValue.length > 1){
          if (newValue.slice(-1)[0].author == 'EMTS'){
            this.showIndicator = false
            if (!this.userScrolled) {
              this.scrollToBottom()
            }
          }
        }
      },
      deep: true
    },
    'emts': {
      handler(newValue, ) {
        messageStore.clearMessageList()
        if (newValue) {
          this.sendTypedMessage('Ask me about the Effective Mindset Training System')
        } else {
          this.sendTypedMessage('What kind of problem are you experiencing?')
        }
      }
    },
    'userScrolled': {
      handler(newValue, oldValue) {
        console.log('userScrolled is: ' + newValue + ' and used to be ' + oldValue)
      }
    }
  }
}
</script>

<style>

.bg-teal {
  background-color: #54b3a3;
}

 .header {
   overflow: hidden;
   position: relative;
   /*background-size: cover;*/
   background-position: center center;
   min-height: calc(100vh - 160px);
 }

 .main-container {
   position: relative;
 }

.probsolv-logo {

}

 .main-headline {
   /*text-shadow: 2px 2px 2px rgba(90,90,90,0.6);*/
   font-weight: 900;
   color: #ffffff;
 }

 .pointer {
   cursor: pointer;
 }

 .chat-indicator {
   position: fixed;
   width: 75px;
   right: 5%;
   bottom: 5%;
   cursor: pointer;
 }

 .chat-modal {
   height: 370px;
 }

 .sc-message {
   width: 90% !important;
   margin: auto 5% !important;
 }
 .sc-message--content.sent {
   max-width: calc(100% - 20px);
 }

.index  .sc-message--content.sent .sc-message--text {
   max-width: calc(100% - 30px) !important;
   background-color: #ffffff !important;
   color: #180798 !important;
 }

 .sc-message--text .sc-message--text-content {
   margin-bottom: 0 !important;
   font-family: 'Prompt', sans-serif;
   font-size: 1.2rem;
 }

.index .sc-message--content.received .sc-message--text {
  background-color: #ff5805 !important;
  color: #ffffff !important;
}

 .index .sc-user-input--text {
   width: 93%;
   font-size: 1.1rem !important;
   line-height: 1.3rem !important;
 }

 .index .sc-user-input--text:empty:before {
   content: '\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0 Let\'s go...';
 }

.index .sc-user-input--text:empty:focus:before {
  content: 'Let\'s go...';
}

.index .sc-user-input--button {
  align-self: center;
  width: 50px !important;
}

 .received .sc-message--text:after {
   content: "";
   position: absolute;
   z-index: 0;
   bottom: 0;
   left: -10px;
   width: 10px;
   height: 20px;
   background: #5e17eb;
   border-bottom-right-radius: 10px;
 }

 .received .sc-message--text:before {
   content: "";
   position: absolute;
   z-index: 0;
   bottom: 0;
   left: -7px;
   height: 20px;
   width: 20px;
   background: #ff5805;
   border-bottom-right-radius: 15px;
 }

 .sent .sc-message--text:before {
   content: "";
   position: absolute;
   z-index: 0;
   bottom: -2px;
   right: -8px;
   height: 20px;
   width: 20px;
   background: #ffffff;
   background-attachment: fixed;
   border-bottom-left-radius: 15px;
 }

 .sent .sc-message--text:after {
   content: "";
   position: absolute;
   z-index: 0;
   bottom: 0;
   right: -10px;
   width: 10px;
   height: 20px;
   background: #5e17eb;
   border-bottom-left-radius: 10px;
 }

 .index .sc-user-input {
   text-align: left;
   position: fixed;
   width: 95%;
   left: 2.5%;
   bottom: 15px;
   background-color: #ffffff !important;
   border-radius: 25px !important;
   z-index: 1001 !important;
 }

div:has(> .sc-user-input) {
  position: fixed;
  bottom:0;
  background-color: #5e17eb;
  height: 60px;
  width: 120%;
  margin-left: -10%;
}

 .index .behind-input {
   position: fixed;
   bottom: 0;
   width: 100%;
   height: 70px;
   background-color: #5e17eb;
   z-index: 997;
 }

.message-icon {
  position: fixed;
  left: 8%;
  bottom: 28px;
  z-index: 999;
}

 @media screen and (min-width: 768px) {
   .index .sc-user-input {
     width: 70%;
     left: 15%;
   }

   .message-icon {
     left: 16.5%;
   }
 }

 .index .sc-chat-window {
   width: 100% !important;
   position: relative !important;
   background: transparent !important;
   max-height: unset !important;
   height: unset !important;
   bottom: 45px !important;
   box-shadow: none !important;
   font-family: 'Prompt', sans-serif !important;
 }
 .sc-chat-window.opened {
   z-index: 998;
 }

 .index .sc-message-list {
   height: unset !important;
   background-color: transparent !important;
   padding-bottom: 0 !important;
 }

 .sc-message--text-content {
   text-align: left;
 }

 .typing-indicator {
   position: relative;
   margin-left: 15px;
   z-index: 999;
   text-align: left;
 }

 .extra-padding {
   padding-bottom: 80px;
 }

 .send-icon {
   pointer-events: none;
   position: fixed;
   right: 18%;
   bottom: 40px;
   z-index: 999;
   cursor: pointer;
 }

 .index .sc-user-input--buttons {
   right: 10px !important;
 }

 .sc-user-input--button-icon-wrapper svg {
   width: 50px;
   height: 30px;
 }

 @media screen and (max-width: 768px){
  .button-width {
    width: 100%;
  }
 }
</style>