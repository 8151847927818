<template>
  <div class="selection-table">
    <MDBContainer class="pb-5">
      <MDBRow>
        <MDBCol md="6" v-for="item in localList" :key="item.title" class="py-4">
          <MDBCard
              class="bg-blue text-light-blue border-blue cursor emotion-card"
              :class="{ selected: item.selected }"
          >
            <MDBCardBody class="pt-1 pb-0" @click="item.selected = !item.selected">
              <h4 class="font-bold text-capitalize">{{  item.title }}</h4>
              <p class="mb-0 mx-1">{{ item.description }}</p>
            </MDBCardBody>
            <MDBCardFooter class="border-0 pb-0" :class="{'pb-3': item.selected }">
              <MDBRange v-if="item.selected" :label="'Intensity: ' + item.intensity.toString()" v-model="item.intensity" :min="0" :max="10" :step="0.5" />
            </MDBCardFooter>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
</template>

<script>
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBRange
} from "mdb-vue-ui-kit";

export default {
  name: "SelectionTable",
  components: {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBRange
  },
  data(){
    return {
      emotions: [
        {
          "emotion": "Happiness",
          "description": "A feeling of joy, pleasure, or well-being.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Sadness",
          "description": "A feeling of sorrow, grief, or unhappiness.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Anger",
          "description": "A strong feeling of displeasure, annoyance, or hostility.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Fear",
          "description": "A feeling of distress or apprehension caused by perceived danger or threat.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Surprise",
          "description": "A sudden, unexpected feeling caused by an unforeseen event or situation.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Disgust",
          "description": "A feeling of strong distaste or aversion, often due to something unpleasant or offensive.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Excitement",
          "description": "A feeling of enthusiasm, eagerness, or heightened interest.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Anxiety",
          "description": "A feeling of worry, nervousness, or unease about an uncertain outcome.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Curiosity",
          "description": "A desire to learn, explore, or investigate something new or unknown.",
          "selected": false,
          "intensity": 0
        },
        {
          "emotion": "Confusion",
          "description": "A feeling of uncertainty or disorientation caused by a lack of understanding or clarity.",
          "selected": false,
          "intensity": 0
        }
      ]
    }
  },
  props: {
    list: Array,
  },
  methods: {

  },
  computed: {
    localList() {
      return [...this.list]
    }
  }
}
</script>

<style lang="scss">
.bg-blue {
  background-color: #154f86;
}
.text-light-blue {
  color: #c9dae7
}
.border-blue {
  border: 5px solid #223b5a;
}

.selected {
  background-color: #c9dae7;
  color: #154f86;
}

.cursor {
  cursor: pointer;
}

.emotion-card {
  transition: all .2s ease-in-out;
}

.emotion-card:hover {
  transform: scale(1.05);
}


.range {
  background: #fff;
  height: 2rem;
  width: 80%;
  margin-left: 10%;
  border-radius: 5rem;
  box-shadow: 1px 5px 5px rgba(black, 0.3);

  display: flex;
  align-items: center;
  justify-content: center;
}

.form-label {
  color: #223b5a !important;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 80%;
  height: 100%;
  background: transparent;

  &:focus {
    outline: none;
  }

  //WEBKIT
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ffffff;
    margin-top: -5px;
    box-shadow: 1px 1px 2px rgba(#000, 0.5);

    cursor: pointer;

  }

  &::-webkit-slider-runnable-track {
    width: 60%;
    height: 9px;
    background: #bdbdbd;
    border-radius: 3rem;

    transition: all 0.5s;
    cursor: pointer;
  }

  &:hover::-webkit-slider-runnable-track {
    background: #223b5a;
  }

  // IE

  &::-ms-track {
    width: 60%;
    cursor: pointer;
    height: 9px;

    transition: all 0.5s;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ffffff;
    margin-top: -5px;
    box-shadow: 1px 1px 2px rgba(#000, 0.5);

    cursor: pointer;
  }

  &::-ms-fill-lower {
    background: #bdbdbd;
    border-radius: 3rem;
  }
  &:focus::-ms-fill-lower {
    background: #ff6e40;
  }
  &::-ms-fill-upper {
    background: #bdbdbd;
    border-radius: 3rem;
  }
  &:focus::-ms-fill-upper {
    background: #ff6e40;
  }

  //FIREFOX
  &::-moz-range-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ffffff;
    margin-top: -5px;
    box-shadow: 1px 1px 2px rgba(#000, 0.5);

    cursor: pointer;
  }

  &::-moz-range-track {
    width: 60%;
    height: 9px;
    background: #bdbdbd;
    border-radius: 3rem;

    transition: all 0.5s;
    cursor: pointer;
  }
  &:hover::-moz-range-track {
    background: #ff6e40;
  }
}

</style>