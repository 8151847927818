import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
// FontAwesomeConfig = { searchPseudoElements: true };

import { faCommentDots } from "@fortawesome/pro-solid-svg-icons";
import { faPaperPlane } from '@fortawesome/sharp-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCommentDots, faPaperPlane)

export default {
    install(Vue) {
        Vue.component('font-awesome-icon', FontAwesomeIcon)
    }
}